<template>
  <div class="max-w-lg mx-auto bg-white p-4 rounded mt-8">
    <label class="text-2xl">Schedule</label>
    <div class="text-left">
      <label class="text-base md:text-md">Chamber</label>
      <Select
        class="w-full border py-3 bg-white outline-none p-2"
        v-model="form.chamber_id"
      >
        <option
          :value="chamber.id"
          v-for="chamber in schedules.chambers"
          :key="chamber.id"
        >
          {{ chamber.name }}
        </option>
      </Select>
    </div>

    <div class="text-left">
      <label>Date</label>
      <date-picker v-model="form.date">
        <template #default="{ inputValue, inputEvents }">
          <input
            class="w-full px-3 py-2 border rounded"
            :value="inputValue"
            v-on="inputEvents"
          />
        </template>
      </date-picker>
    </div>

    <div class="text-left flex gap-2">
      <div>
        <label>Start Time</label>
        <input
          type="time"
          v-model="form.s_time"
          class="w-full py-2 rounded p-2 border"
        />
      </div>
      <div>
        <label>End Time</label>
        <input
          type="time"
          v-model="form.e_time"
          class="w-full py-2 rounded p-2 border"
        />
      </div>
    </div>

    <div class="text-left">
      <label class="text-left">Status</label>
      <Select
        type="text"
        v-model="form.active"
        class="w-full py-2 rounded p-2 border"
      >
        <option value="1">Active</option>
      </Select>
    </div>
    <!-- {{ Object.values(editSchedule.slot_threshold) }} -->

    <div class="text-left flex gap-x-2 mt-2">
      <div>
        <div class="grid">
          <div
            class="flex mb-2 gap-2"
            v-for="(slot_thresold, index) in form.slot_thresholds"
            :key="index"
          >
            <Select
              v-model="form.slot_thresholds[index].support_type_id"
              class="rounded focus:outline-none p-2"
            >
              <option value="">Select Type</option>
              <option
                v-for="support_type in schedules.support_type"
                :key="support_type.id"
                :value="support_type.id"
              >
                {{ support_type.name }}
              </option>
            </Select>
            <input
              class="py-2 p-1 rounded-md w-32 border"
              v-model="form.slot_thresholds[index].slot"
              placeholder="Slot"
            />
            <input
              class="py-2 p-1 rounded-md w-32 border"
              v-model="form.slot_thresholds[index].threshold"
              placeholder="Threshold"
            />
            <button class="col-span-1 text-red-500" @click="remove(index)">
              &#9587;
            </button>
          </div>
        </div>
        <Btn type="button" @click="add()"> + Add</Btn>
      </div>
    </div>
    <div class="flex justify-between items-center mt-4">
      <router-link to="/admin/schedules" class="bg-red-500 text-white rounded-md py-1.5 px-2">Cancel</router-link>
      <Btn class="mt-2" type="submit" @click="submitForm()">Submit</Btn>
    </div>
  </div>
</template>

<script>
import Select from "../../../components/select.vue";
import Btn from "../../../components/btn.vue";
import InputRadio from "../../../components/input-radio.vue";
import { Calendar, DatePicker } from "v-calendar";
import "v-calendar/dist/style.css";
import axios from "../../../store/axios.js";

export default {
  components: {
    Select,
    Calendar,
    DatePicker,
    Btn,
    InputRadio,
  },
  data() {
    return {
      form: {
        timezone: "",
        s_time: "",
        e_time: "",
        chamber_id: "",
        active: "",
        slot_thresholds: [
          {
            threshold: "",
            slot: "",
            support_type_id: "",
          },
        ],
        date: new Date(),
      },
    };
  },
  created() {
    this.$store.dispatch("adminSchedule/createSchedule");
    if (this.scheduleId) {
      this.$store.dispatch("adminSchedule/editSchedule", {
        scheduleId: this.scheduleId,
      });
    }
  },

  computed: {
    schedules() {
      return this.$store.state.adminSchedule.createSchedule;
    },
    editSchedule() {
      let data = this.$store.state.adminSchedule.editSchedule;
      this.form.chamber_id = data.chamber_id;
      this.form.date = data.date;
      return data;
    },
    slotThreshold() {
      console.log(
        "ddf",
        Object.values(this.$store.state.adminSchedule.editSchedule)
      );
    },
    timeSchedule() {},
    scheduleId() {
      return this.$route.params.scheduleId;
    },
    dates: {
      get() {
        return this.formatDate(this.form.date);
      },
    },
    //   chambers(){
    //       console.log(this.schedules)
    //   }
  },
  methods: {
    //   submitForm(){
    //       console.log(this.form.dates, this.form.chamber_id, this.form.s_time, this.form.e_time, this.form.slot_thresholds);
    //   },
    async submitForm() {
      let schedule = await axios()
        .post("/api/schedules", {
          chamber_id: this.form.chamber_id,
          date: this.dates,
          s_time: this.form.s_time,
          e_time: this.form.e_time,
          active: this.form.active,
          slot_thresholds: this.form.slot_thresholds,
        })
        .then(() => {
          this.$toast.success(`Schedule Successffuly Created !!!`, {
            position: "top-right",
            duration: 3000,
          });
          this.$router.push("/admin/schedules");
        });
    },

    formatDate(input) {
      let date = new Date(input);
      let month = "" + (date.getMonth() + 1);
      let day = "" + date.getDate();
      let year = date.getFullYear();

      if (month.length < 2) {
        month = "0" + month;
      }

      if (day.length < 2) {
        day = "0" + day;
      }

      return [year, month, day].join("-");
    },
    add() {
      console.log("asche", this.form.slot_thresolds);
      this.form.slot_thresholds.push({
        threshold: " ",
        slot: " ",
        support_type_id: "",
      });
    },

    remove(index) {
      this.form.slot_thresholds.splice(index, 1);
    },
  },
};
</script>
